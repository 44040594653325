import React, { useState, useEffect, useMemo } from "react";
import { AdminHeader } from "../../../../component";
import { Upload, Select as AntSelect } from "antd";
import Table from "../../../../utils/Table/CommomTable";
import { Button, Modal } from "react-bootstrap";
import CommonModal from "../../../../utils/Modal/CommonModal";
import { Document, Page, pdfjs } from "react-pdf";
import Loader from "../../../User/Loader/Loader";
import { AdminGetAllInvoiceLogsApi } from "../../../../services/AdminApi";
import dayjs from "dayjs";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function AdminTraceInvoiceLogs() {
  const [FullData, setFullData] = useState([]);
  const [loaderFlag, setLoaderFlag] = useState(false);
  const [loaderDataFlag, setLoaderDataFlag] = useState(true);
  const [firstTimeFetch, setFirstTimeFetch] = useState(false);
  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const lastIndex = currentPage * recordPerPage;
  const firstindex = lastIndex - recordPerPage;
  const npage = Math.ceil(totalRecord / recordPerPage);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 700);
  const [sortBy, setSortBy] = useState("");
  const [filters, setFilters] = useState([]);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoicePath, setInvoicePath] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const loadUser = () => {
    setLoaderFlag(true);
    let params = {
      limit: recordPerPage,
      page: currentPage ? currentPage : 1,
      status: false,
    };
    if (search) {
      params.search = search;
    }
    if (sortBy) {
      params.sort_by = sortBy;
    }
    AdminGetAllInvoiceLogsApi({ params })
      .then((res) => {
        console.log("trash invoice logs list = ", res);
        setFullData(
          res.data.result?.list?.map((el, index) => ({
            ...el,
            id: el.id,
            i: index,
          }))
        );
        // csvFunction(res.data.result?.list)
        setTotalRecord(res.data.result?.total);
        setLoaderFlag(false);
      })
      .catch((err) => {
        setLoaderFlag(false);
        console.log({ Message: "Api is not working.", Error: err });
      });
  };

  useEffect(() => {
    setOpenInvoice(false);
    setFirstTimeFetch(true);
    loadUser();
  }, []);

  const handleTableChange = useMemo(() => {
    return (pagination, filters, sorter) => {
      let _filters = [];
      let sortArr = [];
      if (
        Object.values(filters)?.[0] !== null &&
        Object.values(filters)?.[0]?.length > 0
      ) {
        let keys = Object.keys(filters);
        let values = Object.values(filters);

        for (let i = 0; i < keys.length; i++) {
          let obj = {
            k: keys[i],
            v: values[i].join(","),
          };
          _filters.push(obj);
        }
      }
      if (
        typeof sorter === "object" &&
        sorter !== null &&
        !Array.isArray(sorter) &&
        sorter?.field &&
        sorter?.order
      ) {
        let order = sorter.order == "descend" ? "desc" : "asc";
        sortArr.push(`${sorter.field}:${order}`);
      } else if (Array.isArray(sorter)) {
        sorter.map((sort) => {
          let order = sorter.order == "descend" ? "desc" : "asc";
          sortArr.push(`${sort.field}:${order}`);
        });
      }
      setFilters(_filters);
      setSortBy(sortArr.join(","));
      setCurrentPage(pagination.current);
      setRecordPerPage(pagination.pageSize);
    };
  }, [currentPage, recordPerPage, sortBy, filters]);

  const hanleCrtPng = () => {
    setCurrentPage(1);
    setRecordPerPage(10);
  };

  useEffect(() => {
    if (firstTimeFetch) {
      loadUser();
    }
  }, [debouncedSearch, currentPage, recordPerPage, sortBy, filters]);

  // Custom hook for debouncing
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        hanleCrtPng();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const dataSource = FullData;

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (cell, row) => (
        <>{row?.invoice_number ? row?.invoice_number : "NA"}</>
      ),
      //   width: "15%",
      sorter: {
        compare: (a, b) => a.invoice_number - b.invoice_number,
        multiple: 1,
      },
    },
    {
      title: "Organization Name",
      dataIndex: "comapny",
      key: "company",
      render: (cell, row) => <>{row?.company ? row?.company : "NA"}</>,
      //   width: "15%",
      //   sorter: {
      //     compare: (a, b) => a.invoice_number - b.invoice_number,
      //     multiple: 1,
      //   },
    },
    {
      title: "File",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (cell, row) => (
        <>
          <img
            src="/images/pdf-black.svg"
            className="cursorPointer"
            onClick={(e) => handleInvoiceModal(row?.invoice_file)}
          />
        </>
      ),
      // width: "15%",
      //   sorter: {
      //     compare: (a, b) => a.invoice_number - b.invoice_number,
      //     multiple: 1,
      //   },
    },
    {
      title: "Invoice Captured Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (cell, row) => <>{dayjs(row?.created_at).format("D MMM YYYY")}</>,
      // width: "15%",
      //   sorter: {
      //     compare: (a, b) => a.invoice_number - b.invoice_number,
      //     multiple: 1,
      //   },
    },
  ];

  const handleInvoiceModal = (path) => {
    console.log("path = ", path);
    setInvoicePath(path);
    setOpenInvoice(!openInvoice);
  };
  const handleInvoiceClose = () => {
    setOpenInvoice(!openInvoice);
    setInvoicePath("");
    console.log("closed");
  };
  const handelSearch = (e) => {
    let src = e.target.value.trim();
    setSearch(src);
  };

  const handleReset = () => {
    setSearch("");
    hanleCrtPng();
  };

  return (
    <>
      {/* <DashboardHeader title="Trash Invoice Logs" switchStatus={false} /> */}
      <AdminHeader title="Trash Invoice Logs" />
      <div className="bg-white py-3 px-3 py-md-5 px-md-4 bigRadius h-100 m-3 m-md-4">
        <div className="row">
          <div className="col-md-6 text-white">k</div>
          <div className="col-md-6">
            <div className="position-relative me-2 table-search w-100">
              <input
                className="form-control me-2 rounded-pill py-3 ps-5 bg-light searchBtn"
                type="search"
                value={search}
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => handelSearch(e)}
              />
              <button className="btn position-absolute searchIcon">
                <i
                  className="fa fa-search fs-4 text-secondary"
                  aria-hidden="true"
                ></i>
              </button>
              {search?.length > 0 && (
                <div
                  className="clearBtn position-absolute rounded-circle setLimit"
                  onClick={handleReset}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          {/* <div className="bg-white py-3 px-3 py-md-5 px-md-4 bigRadius h-100 m-3 m-md-4"> */}
          {/* <div className="tab-content reportModuleTable" id="myTabContent"> */}
          <div
            className="tab-pane fade show active removeScroll"
            id="allTable"
            role="tabpanel"
            aria-labelledby="allTable-tab"
          >
            <Table
              className="mt-4 "
              dataSource={dataSource}
              columns={columns}
              pagination={{
                pageSize: recordPerPage,
                current: currentPage,
                total: totalRecord,
                selectComponentClass: AntSelect,
              }}
              onChange={handleTableChange}
            />
          </div>
          {loaderFlag && loaderDataFlag ? <Loader /> : ""}
        </div>
      </div>
      {/* </div>
      </div> */}
      {/* Invoice Modal View Modal */}
      <CommonModal
        show={openInvoice}
        handleClose={handleInvoiceClose}
        title="View Invoice"
      >
        <Modal.Body className="addContract">
          <div className="px-0 px-md-4 pdfViewer">
            <div>
              <nav className="d-flex align-items-center justify-content-between bg-white position-sticky pdfNav py- px-2 py-3">
                <div>
                  <button onClick={goToPrevPage}>Prev</button>
                  <button onClick={goToNextPage}>Next</button>
                </div>
                <p className="pdfPageCount">
                  {pageNumber} of {numPages}
                </p>
              </nav>
              <Document
                file={invoicePath}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </div>
        </Modal.Body>
      </CommonModal>
    </>
  );
}

export default AdminTraceInvoiceLogs;
